<template>
  <div class="wrapper">
    <el-card class="box-card user-basic-info">
<!--      <div class="recharge-btn">-->
<!--        <el-button type="primary" @click="dialogVisible=true">充值</el-button>-->
<!--      </div>-->
      <el-descriptions title="用户信息">
        <el-descriptions-item label="ID">{{ userInfo?.id }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ userInfo?.mobile }}</el-descriptions-item>
        <el-descriptions-item label="昵称">{{ userInfo?.nickname }}</el-descriptions-item>
        <el-descriptions-item label="注册日期">{{ userInfo?.created_at }}</el-descriptions-item>
        <el-descriptions-item label="到期时间">{{ userInfo?.expire_date }}</el-descriptions-item>
        <el-descriptions-item label="会员类型">2024-10-10</el-descriptions-item>
        <el-descriptions-item label="邀请人信息">
          {{ userInfo?.invite_user?.id }} | {{ userInfo?.invite_user?.nickname }} |
          {{ userInfo?.invite_user?.mobile }}
        </el-descriptions-item>
        <el-descriptions-item label="代理商信息">{{ userInfo?.agent?.id }} | {{
            userInfo?.agent?.name
          }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>


    <el-card class="box-card" v-if="uid">
      <el-tabs v-model="activeName">
        <el-tab-pane label="产品信息" name="productInfo">
          <product-info :uid="uid"></product-info>
        </el-tab-pane>
        <el-tab-pane label="账号管理" name="accountManage">
          <account-manage :uid="uid"></account-manage>
        </el-tab-pane>
        <el-tab-pane label="订单管理" name="orderManage">
          <order-manage :uid="uid" :key="orderKey"></order-manage>
        </el-tab-pane>
        <el-tab-pane label="赠送管理" name="orderGiveManage">
          <order-give-manage :uid="uid" :key="orderKey"></order-give-manage>
        </el-tab-pane>
        <el-tab-pane label="浏览记录" name="browsingHistory">
          <browsing-history :uid="uid"></browsing-history>
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <el-dialog
        v-if="uid"
        title="平台下单"
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose">
      <recharge-form :uid="uid" @recharge-success="handleClose"></recharge-form>
    </el-dialog>

  </div>
</template>

<script>
import accountManage from "@/views/user/components/accountManage.vue";
import browsingHistory from "@/views/user/components/browsingHistory.vue";
import productInfo from "@/views/user/components/productInfo.vue";
import orderManage from "@/views/user/components/orderManage.vue";
import orderGiveManage from "@/views/user/components/orderGiveManage.vue";
import rechargeForm from "@/views/user/components/rechargeForm.vue";
import {getDetailAPI} from './api'

export default {
  name: 'Detail',
  components: {accountManage, browsingHistory, productInfo, orderManage, rechargeForm, orderGiveManage},
  props: {
    detail: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      activeName: 'productInfo',
      uid: 0,
      dialogVisible: false,
      userInfo: {},
      orderKey: 0,
    }
  },

  mounted() {
    this.uid = this.$route.params.id

    getDetailAPI(this.uid).then(res => {
      this.userInfo = res
    })
  },

  methods: {
    handleClose() {
      this.dialogVisible = false
      this.orderKey += 1
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  .box-card {
    margin-bottom: 15px;
  }

  .user-basic-info {
    padding-top: 10px;
    position: relative;

    .recharge-btn {
      top: 3px;
      position: absolute;
      right: 10px;
    }
  }
}
</style>
