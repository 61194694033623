<template>
    <el-table
        :data="userList"
        style="width: 100%">
        <el-table-column prop="id" label="id" width="180"></el-table-column>
        <el-table-column prop="nickname" label="昵称" width="180"></el-table-column>
        <el-table-column prop="mobile" label="手机号码"></el-table-column>
        <el-table-column prop="is_main" label="是否主账号">
            <template slot-scope="{ row }">
                <el-tag v-if="row.is_main">是</el-tag>
                <el-tag v-else>否</el-tag>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import {getChildUser} from '../api'

export default {
    name: "AccountManage",
    props: {
        uid: {
            type: [Number, String],
            default() {
                return 0
            }
        }
    },
    data() {
        return {
            userList: []
        }
    },
    mounted() {
        getChildUser(this.uid).then(res => {
            this.userList = res.data || []
        })
    }
}
</script>

<style scoped lang="scss">

</style>
