<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="公司产品">
        <el-radio-group v-model="form.product_id">
          <el-radio :label="item.id" v-for="item in vipProductList" :key="item.id">{{ item.product_title }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="购买数量">
        <el-input-number v-model="form.buy_num" :min="1" :max="100" label="购买数量"></el-input-number>
        (原价：{{ oriPayPrice }}元)
      </el-form-item>
      <el-form-item label="付费">
        <el-input-number v-model="form.pay_price" :precision="2" :step="0.1" :max="10000"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="onSubmit">购买</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getVipProductListAPI, rechargeByPlatformAPI} from '../api'

export default {
  name: "rechargeForm",
  data() {
    return {
      form: {
        uid: this.uid,
        product_id: 0,
        buy_num: 1,
        pay_price: '',
      },
      vipProductList: []
    }
  },

  computed: {
    oriPayPrice() {
      let currentProduct = this.vipProductList.find(item => item.id === this.form.product_id)
      if (!currentProduct) {
        return ''
      }

      return this.form.buy_num * currentProduct.price
    }
  },

  props: {
    uid: {
      type: Number,
      default() {
        return 0
      }
    }
  },

  mounted() {
    this.getVipProductList()
  },
  methods: {
    onSubmit() {
      this.$confirm('确认充值么?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            rechargeByPlatformAPI(this.form).then(() => {
              this.$message.success('充值成功')
              this.reset()
              this.$emit('recharge-success')
            })
          })
          .catch(() => {
            this.reset()
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
    },
    reset() {
      this.form = {
        uid: this.uid,
        product_id: 0,
        buy_num: 1,
        pay_price: '',
      }
    },
    handleClose() {
      this.$emit('recharge-success')
    },
    getVipProductList() {
      getVipProductListAPI().then(res => {
        this.vipProductList = res.data || []
        this.vipProductList.map(item => item.product_title = `${item.title}  (${item.price}元/${item.year}年)`);
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
