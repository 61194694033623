<template>
    <div>
        <div v-if="!productInfo?.id">暂无数据</div>
        <el-descriptions v-else :column="1" border>
            <el-descriptions-item label="类型">{{productInfo.type_dsc}}</el-descriptions-item>
            <el-descriptions-item label="账号数">{{productInfo.account_num}}</el-descriptions-item>
            <el-descriptions-item label="到期时间">{{productInfo.expire_time}}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
import {getUserProductInfo} from '../api'
export default {
    name: "ProductInfo",
    props: {
        uid: {
            type: [Number, String],
            default() {
                return 0
            }
        }
    },
    data() {
        return {
            productInfo: {}
        }
    },

    mounted() {
        console.log('productInfo', this.uid)
        getUserProductInfo(this.uid).then(res => {
            this.productInfo = res
        })
    }

}
</script>

<style scoped lang="scss">

</style>
